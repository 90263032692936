<template>
    <div class="our-partner-home-section">
        <h2
            @click="goTo()"
            class="our-partner-home-section-header text-center fs-1 py-3 text-decoration-underline"
        >
            {{ $t("ourPartnersTitle") }}
        </h2>
        <ScrollAnimation :animatedClass="'animate-enter-two'">
            <div class="container">
                <div class="row pb-5" style="border-radius: 20px">
                    <!-- <div
                        class="col-5 p-2 d-flex flex-column align-items-center justify-content-center mob-flex-wrap"
                    >
                        <a
                            class="text-white d-block"
                            target="_blank"
                            href="https://zolaelectric.com/"
                        >
                            <img
                                class="h-50"
                                width="150"
                                height="150"
                                src="../../assets/imgs/our-partners/zola-logo.jpg"
                                alt=""
                            />
                            <h2
                                class="w-100 text-decoration-underline my-2 text-center"
                            >
                                {{ $t("sectionPartners.header.zola") }}
                            </h2>
                            <p
                                class="text-justify p-3 scroll-style"
                                style="max-height: 200px; overflow: auto"
                            >
                                {{ $t("sectionPartners.zola") }}
                            </p>
                        </a>
                    </div> -->
                    <div
                        class="col-5 p-2 d-flex flex-column align-items-center justify-content-center mob-flex-wrap"
                    >
                        <a
                            class="text-white d-block"
                            target="_blank"
                            href="http://stecmax.ca/en"
                        >
                            <img
                                class="h-50"
                                width="150px"
                                height="150px"
                                src="../../assets/imgs/our-partners/stecmax-logo.png"
                                alt=""
                            />
                            <h2
                                class="w-100 text-decoration-underline my-2 text-center"
                            >
                                {{ $t("sectionPartners.header.stecMax") }}
                            </h2>
                            <p
                                class="text-justify p-3 scroll-style"
                                style="max-height: 200px; overflow: auto"
                            >
                                {{ $t("sectionPartners.stecMax") }}
                            </p>
                        </a>
                    </div>
                    <div
                        @click="goTo()"
                        class="mx-auto col-2 show-more-partners d-flex flex-column align-items-center justify-content-center px-5 card-more"
                        style="transition: all 0.5s ease-in-out"
                    >
                        <img
                            class="show-more-partners-img d-flex justify-content-center align-items-center"
                            src="@/assets/imgs/our-partners/more.png"
                            alt=""
                        />
                        <!-- <p>show More</p> -->
                    </div>
                </div>
            </div>
        </ScrollAnimation>
    </div>
</template>
<script>
import ScrollAnimation from "@/components/general-components/ScrollAnimation.vue";
export default {
    name: "OurPartnersHomeSection",
    components: { ScrollAnimation },
    data() {
        return {};
    },

    mounted() {},

    methods: {
        goTo() {
            this.$router.push("/our-partners");
        },
        // goToZola() {
        //     this.$router.push("https://zolaelectric.com");
        // },
        goToStecmax() {
            this.$router.push("http://stecmax.ca/en");
        },
    },
};
</script>
<style lang="scss">
.our-partner-home-section {
    min-height: 300px;
    background-color: #043041;
    padding: 20px;
    color: white;
    .our-partner-home-section-header {
        cursor: pointer;

        padding: 20px;
        padding-top: 70px;
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        color: white;
        text-shadow: 1px 1px 22px lightgreen;
        text-transform: uppercase;
    }
}
.show-more-partners {
    cursor: pointer;
}
// scroll Style
/* Chrome, Safari, and Opera */
.scroll-style::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.scroll-style::-webkit-scrollbar-track {
    background: #757575; /* Background of the scrollbar track */
}

.scroll-style::-webkit-scrollbar-thumb {
    background: #888888; /* Color of the scrollbar thumb */
    border-radius: 3px; /* Rounded corners */
}

.scroll-style::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovering over the scrollbar thumb */
}

/* Firefox */
.scroll-style {
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #888 #f1f1f1; /* Thumb color, Track color */
}

/* Internet Explorer 10+ */
.scroll-style {
    -ms-overflow-style: -ms-autohiding-scrollbar; /* Show scrollbar only while scrolling */
}

//
.hover-more {
    transition: all 0.5s ease-in-out;
    &:hover {
        background-color: white !important;
        color: gray !important;
    }
}
.card-more {
    &:hover {
        transform: translateY(-10px);
    }
}

@media screen and (max-width: 600px) {
    .mob-flex-wrap {
        width: 350px !important;
    }
    .show-more-partners {
        min-width: 200px !important;
        margin-top: 50px;
    }
    .show-more-partners-img {
        width: 100% !important;
        justify-content: center !important;
        align-items: center;
    }
}
</style>
