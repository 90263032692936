<template>
    <!-- <div
        id="carouselExampleIndicators"
        class="carousel slide slider-home-container"
        data-bs-ride="carousel"
        data-bs-interval="3000"
    >
        <div class="carousel-indicators">
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="4"
                aria-label="Slide 5"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="5"
                aria-label="Slide 6"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="6"
                aria-label="Slide 7"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="7"
                aria-label="Slide 8"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="8"
                aria-label="Slide 9"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="9"
                aria-label="Slide 10"
            ></button>
        </div>
        <div class="carousel-inner slider-img-container">
            <div class="carousel-item active">
                <img
                    src="@/assets/imgs/designs/engineering/zola-1.png"
                    class="d-block w-100"
                    alt="Zola Solar-system"
                />
            </div>
            <div class="carousel-item active">
                <img
                    src="@/assets/imgs/designs/engineering/home-slider-drone.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/imgs/designs/engineering/home-slider-1.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/imgs/designs/engineering/home-slider-2.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/imgs/designs/engineering/engineering-7.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/imgs/designs/engineering/engineering-2.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/imgs/designs/engineering/engineering-3.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/imgs/designs/engineering/home-slider-4.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/imgs/designs/engineering/engineering-4.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
            <div class="carousel-item">
                <img
                    src="@/assets/imgs/designs/engineering/engineering-6.jpg"
                    class="d-block w-100"
                    alt="..."
                />
            </div>
        </div>
        <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div> -->
    <div
        id="carouselExampleIndicators"
        class="carousel slide slider-home-container"
        data-bs-ride="carousel"
        data-bs-interval="3000"
    >
        <div class="carousel-indicators">
            <button
                v-for="(indicator, index) in indicators"
                :key="index"
                type="button"
                :data-bs-target="'#carouselExampleIndicators'"
                :data-bs-slide-to="index"
                :class="{ active: index === 0 }"
                :aria-label="'Slide ' + (index + 1)"
            ></button>
        </div>
        <div class="carousel-inner slider-img-container">
            <div
                v-for="(image, index) in images"
                :key="index"
                :class="['carousel-item', { active: index === 0 }]"
            >
                <img
                    :src="require(`@/assets/imgs/designs/engineering/${image}`)"
                    class="d-block w-100"
                    :alt="`Slide ${index + 1}`"
                />
            </div>
        </div>
        <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>
<script>
export default {
    name: "SliderComponents",
    data() {
        return {
            indicators: 11,
            images: [
                // "zola-1.png",
                "stecmax.jpeg",
                "home-slider-drone.jpg",
                "home-slider-1.jpg",
                "home-slider-2.jpg",
                "engineering-7.jpg",
                "engineering-2.jpg",
                "engineering-3.jpg",
                "home-slider-4.jpg",
                "engineering-4.jpg",
                "engineering-6.jpg",
            ],
        };
    },
};
</script>
<style lang="scss">
// .slider-container {
//     margin-top: var(--app-header-height);
// }
.slider-img-container {
    & img {
        width: 100vw;
        height: 100vh;
    }
}
@media screen and (max-width: 800px) {
    .slider-img-container {
        & img {
            width: 100vw;
            height: 80vh;
        }
    }
}
</style>
